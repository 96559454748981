import React, { useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';

import { nowDate } from '../actions/dateAction';
import { getPointsSMSApi, loadConsumersFromDB , loadSettingsFromDb , loadVisitsOfDayFromDB , loadWorkersFromDB , getDeviceInfo , makeBackup , createLocalBackup} from '../customfunc';
import { TIME_INTERVAL_OTHER_FETCH } from '../global';

import { loadConsumers } from '../actions/consumersActions';
import { loadWorkers } from '../actions/workersActions';
import { setVisits } from '../actions/visitsActions';
import { loadSettings, setPoints , setDeviceInfo } from '../actions/settingsActions';

import './Main.css';
import Top from './Top';
import Central from './Central';



//import { createContext } from 'react/cjs/react.production.min';

const Loading=()=> {
  return(
    <>
    <div className='loadingMainBox'>
      <div className='loadingDivBox'>
        <div className='loadingTextBox'>
          <div className='loadingText'>Loading ...</div>
        </div>
      </div>
    </div>
    </>
  )
}

const NetError=()=> {
  return(
    <>
    <div className='errorMainBox'>
      <div className='errorDivBox'>
        <div className='errorTextBox'>
          <div className='errorText'>Błąd sieci...</div>
        </div>
      </div>
    </div>
    </>
  )
}
/*
const NewDevice=(props)=>{
      return (
          <>
          <div className='bgOpenMenu'>
              <div className='openMenu'>
                  <div>
                      <div className='row-1'>
                          <div className='mainBarVisit '>
                              <div className='topBar'>
                                  Logujesz się pierwszy raz na nowym urzędzeniu?
                              </div>
                              <div className='centralBar'>
                                  <div className='centralBarMessagePanel'>
                                      <div className='general'>
                                          <label className='generalLabel' style={{backgroundColor: '#f0f0f0'}} >Powiadamianie SMS o wizytach</label>
                                          <div className='mt-2'>Liczba wiadomości SMS do wysłania:</div>
                                      </div>   
                                  </div>
                              </div>
                              <div className='bottomBar'>
                                  <div className='buttons_main'>
                                      <button type="button" className="btn btn-dark btn-sm button-make" onClick={()=>props.handleCloseMessagePanel()}>Zamknij</button>
                                  </div>
                                  
                              </div>
                          </div>
                          
                      </div>
                      
                  </div>
              </div>
          </div>
          </>
      )
  }
*/

const Main =()=> {
const currentDateTime = useSelector(store => store.currentDateTime);
const consumers = useSelector(store => store.consumers);
const workers = useSelector(store => store.workers);
const settings = useSelector(store => store.settings);
const appSettings = useSelector(store => store.netStatus);
const dispatch = useDispatch();

const isLoading=()=> {
if(settings !== null && currentDateTime !==null && consumers!== null && workers!==null )  return true;
else return false;
}   

const makeLocalBackup=(isBck , lastLocalBackup)=> {
  if(isBck) {
      let backupTime = new Date();
      backupTime.setHours(12 , 0 , 0 , 0);
      let pastBackupDate = Date.parse(lastLocalBackup).toString() === 'NaN' ? new Date(0) : new Date(lastLocalBackup);
      pastBackupDate.setHours(12 , 0 , 0 , 0);
      if(backupTime > pastBackupDate) {
          makeBackup().then((json)=> {  
          const fileName = json && (json.fileName ? json.fileName : '' );
          createLocalBackup(fileName);
          })
          .catch(()=>console.log('bład połączenia: '));
      }
  }
}


useEffect( () => {
loadSettingsFromDb().then((json)=>dispatch(loadSettings(json[0])) );  
dispatch( nowDate() );
loadConsumersFromDB().then((json)=> dispatch( loadConsumers(json) ) );
loadWorkersFromDB().then((json) => dispatch( loadWorkers(  json ) ));
loadVisitsOfDayFromDB().then((json)=>dispatch(setVisits(json)));
setTimeout(()=>{
    getPointsSMSApi().then((json)=> {
      if(json.status){
        if(json.status === 'success') dispatch(setPoints(json.points));
        if(json.status === 'error') alert( json.message ) ;
      }
      else {
        alert( 'Błąd obsługi API' ) ;
      }
    });
},TIME_INTERVAL_OTHER_FETCH);

setTimeout(()=>{
getDeviceInfo().then((json)=> {
  if(json) {
    if(json.status){
      if(json.status === 'success') {
        const iIsBckLocal = parseInt(json.isBackupLocal)
        dispatch(setDeviceInfo(iIsBckLocal , json.lastLocalBackup , json.deviceId));
        iIsBckLocal && setTimeout(()=> makeLocalBackup(iIsBckLocal , json.lastLocalBackup ) , TIME_INTERVAL_OTHER_FETCH * 3 );
      }
      if(json.status === 'error') alert( json.message ) ;
    }
    else {
      alert( 'Błąd obsługi API' ) ;
    }
 }
  else {
    alert( 'Błąd obsługi API' ) ;
  }
});
},TIME_INTERVAL_OTHER_FETCH*2);

} , [] );


    return (
      <>
      <div>
        { appSettings.frozenApp && <Loading /> }
        { !appSettings.netStatus && <NetError /> }
        {/* { true && <NewDevice /> } */}
        {false && <div className='alertInfoMainBox'><div className='alertInfoFixed'>aaaa</div></div>}
      </div>
      <div >
            <Top />
            {isLoading() && <Central  /> }
      </div>
      </>
    );
  
}

export default Main;


