import React, { useEffect, useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { loadSettingsFromDb } from '../customfunc';
import { loadSettings } from '../actions/settingsActions' 

import './SettingsApp.css';
import { urlAppFile } from '../global';
//import  md5  from 'crypto-js/md5';

const staticMenu = true;
    let pos = {x: 0 , y: 0};  
    let mMove = false;  

const ConfigureProvider = (props) => {
const dispatch = useDispatch();    

const settings = useSelector(store => store.settings); 

    const handleMouseDown=(e)=>
    {
        if(!staticMenu) {
            let mainBar = document.getElementById('mainBar');
            mainBar.style.position = 'absolute';
            let xFar = e.pageX - mainBar.offsetLeft ;
            let yFar = e.pageY -mainBar.offsetTop ;
            pos = { 
                x: xFar,
                y: yFar
            }
            mMove = true;
        }
    }  

    const handleMouseUp=(e)=>
    {
        mMove = false;
    } 

    const handlerMouseMove=(ex)=>
    {
        if(mMove) {
            let mainBar = document.getElementById('mainBar');
            mainBar.style.left = `${ex.pageX-pos.x}px`; 
            mainBar.style.top = `${ex.pageY-pos.y}px`;
            }
    }    


const handleSave=()=> {
const tokenSMSApi = document.getElementById('inputToken1').value ;
const company_short_name = settings.company_short_name;  
const smsPrice = document.getElementById('InputText2').value ;
const limitAlertSMS = document.getElementById('InputText3').value ;
          var objCon = { 
            Action: "saveSMSAPIProviderSettings" ,
            tokenSMSApi ,
            company_short_name,
            smsPrice,
            limitAlertSMS
          };
        
          var urlData = new URLSearchParams();
          urlData.append('var1', JSON.stringify(objCon) );  
          fetch(urlAppFile , {
              method: 'POST' ,  
              body: urlData  
              })
              .then(response => response.json())
              .then(json => {
                if(json.status) {
                    if (json.status === "success" ) {
                        loadSettingsFromDb().then((json)=>dispatch(loadSettings(json[0])) );
                        alert('Zapisano');
                        props.handleCloseConfigureProviderDialog();   
                        
                    }
                    else {
                        alert('Brak możliwości zapisu do bazy danych. Sprawdź połączenie internetowe');
                    }
                }
              }
              )

      

} 

const handleClose=()=> {
props.handleCloseConfigureProviderDialog();    
} 

useEffect( ()=> {

} , []);

const smsPrice = settings.sms_api_sms_price >= 0 ? settings.sms_api_sms_price : 0;
const smsAlertLimit = settings.sms_api_min_limit_alert >= 0 ? settings.sms_api_min_limit_alert : 0;
    return ( 
     <>
            <div className="openMenu">
            <div className="row-1">
                
                    <div id="mainBar" className="mainBarConfigSmsProvider" onMouseMove={handlerMouseMove}>
                        <div id="topBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                            <p style={{textAlign: "left"}}>Konfiguracja dostawcy usługi powiadamiania SMS</p>
                        </div>
                        <div className="centralBar">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="InputLogin1" className="form-label">Token SMSApi.pl</label>
                                <input type="login" className="form-control" id="inputToken1" aria-describedby="tokenHelp" />
                                <div id="tokenHelp" className="form-text">token pobrany z portalu SMSApi.pl</div>
                                <div id="tokenHelp" className="form-text">Pole "Nadawca" musi być zgodne z ustawieniami w portalu SMSApi.pl</div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="InputText" className="form-label">Cena za wysłanego sms</label>
                                <input type="text" className="form-control" id="InputText2" aria-describedby="tokenHelp" defaultValue={smsPrice}/>
                                <div id="tokenHelp" className="form-text">cennik na stronie dostawcy bramki sms</div>
                                <div id="tokenHelp" className="form-text">Ilość możliwych do wysłania wiadomości: {Math.floor(settings.points/smsPrice)}</div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="InputText3" className="form-label">Alert pozostałej liczby SMS</label>
                                <input type="text" className="form-control" id="InputText3" aria-describedby="tokenHelp" defaultValue={smsAlertLimit}/>
                                <div id="tokenHelp" className="form-text">Minimalna liczba sms-ów po przekroczeniu której wyświetlony zostanie alert</div>
                            </div>
                            
                            </form>
                        </div>
                        <div className="bottomBar">
                            <div className="buttons_main">
                            <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleSave}>Zapisz</button>                  
                            <button type="button" className="btn btn-dark btn-sm button-close" onClick={handleClose}>Zamknij</button>
                            </div></div>
                    </div>
                
                
            </div>
        </div>
     </>   
     );
}
 


const SettingsApp = () => {
const settings = useSelector(store => store.settings); 

const [limit , setLimit ] = useState(0); 
const [configureProviderDialog , setConfigureProviderDialog] = useState(false);
const [btnsServices , setBtnsServices] = useState([]);
const [licznik , setlicznik] = useState(0); ////tymczasowe rozwiązanie. setBtnsServices(jData); nie uaktualnie z niewiadomych przyczyn SettingsApp
const dispatch = useDispatch();

let currentGMT = settings.time_zone;
if(currentGMT > 0 ) currentGMT = '+'+ currentGMT;
const companyShortName = settings.company_short_name;    
const companyLongName = settings.company_long_name;
const companyLogo = settings.company_logo;
const activeNotification = parseInt(settings.active_notification) === 0 ? false : true ;
const dayInterval = settings.day_interval;
const senderName = settings.sender_name;
const textNotification = settings.text_notification;
const visibleName = parseInt(settings.visible_name_worker);
const btnServices = JSON.parse(settings.buttons_services);
const aBtnServices = btnServices.buttons;
const visibleDays = parseInt(settings.visible_not_current_days) === 1 ? true : false ;
const schemeDaysCalendar = parseInt(settings.vertical_annual_calendar);
const deviceId = settings.deviceId;




useEffect( ()=> {
setLimit( textNotification.length );
setBtnsServices( aBtnServices ); 
} , [] )

const handleChangeTextArea=(e)=>{
setLimit( e.target.value.length );
}

const handleConfigureProvider=()=> {
setConfigureProviderDialog(true);
}

const handleCloseConfigureProviderDialog=()=> {
    setConfigureProviderDialog(false);
    }

const handleAddRowToServicesTable=()=> {
let btnsSrv = btnsServices;
const objJSON = JSON.parse('{"name":"" , "time":""}');
btnsSrv.push(objJSON);
const jData = btnsSrv;
//setBtnsServices({});
setBtnsServices(jData);
setlicznik(licznik+1); //tymczasowe rozwiązanie. setBtnsServices(jData); nie uaktualnie z niewiadomych przyczyn SettingsApp
}    

const loadObjectsButtonOfServices=()=>{
if(btnsServices !== undefined) {  
const Arr = btnsServices.map((element , index) => {
    return (
       <div key={index}>
            <div className="input-group">
                <input type="text" id={index+'-name'} aria-label="First name" className="form-control" defaultValue={element.name}/>
                <input type="text" id={index+'-time'} aria-label="Last name" className="form-control"defaultValue={element.time}/>
            </div>
        </div>
    );
 });
return Arr;  
}
else return [];

}

const handleSaveSettings=()=> {
const companyShortName = document.getElementById('inputCompanyShortName').value;    
const companyLongName = document.getElementById('inputCompanyLongName').value;
const timeZone = document.getElementById('inputGMT').value;
const companyLogo = document.getElementById('inputCompanyLogo').value;
const activeNotification = document.getElementById('flexSwitchCheck2').checked;
const dayInterval = document.getElementById('inputDayInterval').value;
const senderName = document.getElementById('inputSenderName').value;
const textNotification = document.getElementById('notificationTextarea1').value;
const visibleNameWorker = document.getElementById('flexRadioCal1').checked ? 1 : 2;
const visibleNotCurrentDays = document.getElementById('flexSwitchCheckVisibleDays').checked  ;

if(companyShortName === '' || companyShortName === undefined) {
    alert("Krótka nazwa firmy jest wymagana");
    return false;
}

let obj;
let servicesButtons = {buttons:[]}
for( let x = 0 ; ; x++) {
    obj = document.getElementById(x+'-name');
    if(obj === null) break;
    if(obj === undefined) break;
    const nameSrv = obj.value;
    const timeSrv = document.getElementById(x+'-time').value;
    let a = {"name" : nameSrv , "time" : timeSrv}; 
    servicesButtons.buttons.push(a);
}
servicesButtons = JSON.stringify(servicesButtons);

var objCon = {
    Action: "saveSettings",
    companyShortName ,
    companyLongName ,
    companyLogo ,
    timeZone ,
    activeNotification ,
    dayInterval ,
    senderName ,
    textNotification ,
    visibleNameWorker ,
    visibleNotCurrentDays ,
    servicesButtons
    };      
var urlData = new URLSearchParams();
urlData.append('var1', JSON.stringify(objCon) );  
fetch(urlAppFile , {
    method: 'POST' ,  
    body: urlData  
    })
    .then(response => response.json())
    .then(jdata => {
     if(jdata.status === "success") {
        alert('zapisano');
        loadSettingsFromDb().then((json)=>dispatch(loadSettings(json[0])) );
        }
     else {
        const d = JSON.parse(jdata);
        alert(d.error);
     }   
    }) 

}
    return ( 
        <>
        {configureProviderDialog && <ConfigureProvider handleCloseConfigureProviderDialog={handleCloseConfigureProviderDialog} />}
        <div className="col"> 
        <p>Ustawienia Ogólne</p>


         <div className='general'>
            <label className='generalLabel' >Nazwa Firmy</label>
            <div className="mb-3 row">
                <label htmlFor="inputCompanyLongName" className="col-sm-2 col-form-label">Krótka nazwa firmy</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="inputCompanyShortName" defaultValue={companyShortName} />
                </div>
            </div>





            <div className="mb-3 row">
                <label htmlFor="inputCompanyLongName" className="col-sm-2 col-form-label">Pełna nazwa firmy</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="inputCompanyLongName" defaultValue={companyLongName} />
                </div>
            </div>
        </div>

        <div className='general'>
            <label className='generalLabel' >Logo Firmy</label>
            <div className="mb-3 row">
                <label htmlFor="inputCompanyLogo" className="col-sm-2 col-form-label">Logo Firmy</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="inputCompanyLogo" defaultValue={companyLogo} />
                </div>
            </div>
        </div>

        <div className='general'>
            <label className='generalLabel' >Strefa czasowa</label>
            <div className="mb-3 row">
                <label htmlFor="inputGTM" className="col-sm-2 col-form-label" >GMT</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="inputGMT" disabled defaultValue={currentGMT} />
                </div>
            </div>
        </div>

        <div className='general'>
            <label className='generalLabel' >Powiadamienie o wizycie</label>
            <div className="mb-3 row">
                <div className="col-sm-10">
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheck2" defaultChecked={ activeNotification }  />
                    <label className="form-check-label" forhtml="flexSwitchCheck2">Powiadamianie aktywne</label>
                    </div>

                </div>

                <div className="mb-3 row">
                <label htmlFor="inputDayInterval" className="col-sm-2 col-form-label">Czas powiadamiania przed wizytą w dniach</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="inputDayInterval" defaultValue={ dayInterval  } />
                </div>
            </div>


            <div className="mb-3 row">
                <label htmlFor="inputSenderName" className="col-sm-2 col-form-label">Nadawca</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="inputSenderName" defaultValue={ senderName } />
                </div>
            </div>

            <div className="mb-3">
                <label forhtml="notificationTextarea1" className="form-label">Tekst powiadomienia</label>
                <textarea className="form-control" id="notificationTextarea1" rows="3" onChange={handleChangeTextArea} defaultValue ={ textNotification } ></textarea>
                <p>{limit}/160</p> 
                </div>
               
            </div>
            <span>Dostawca Usłgi powiadamiania SMS</span>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                SMSApi.pl
                </label>
                </div>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                Outher Provider
                </label>
            </div>
            <div >
                    <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleConfigureProvider}>Konfiguracja</button>
            </div>            

        </div>
        <div className='general'>
            <label className='generalLabel' >Ustawienia kalendarza</label>
            <span>Wyświetlana nazwa pracownika w kalendarzu</span>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioCal" id="flexRadioCal1" defaultChecked={visibleName===1} />
                <label className="form-check-label" htmlFor="flexRadioCal1">
                Pełna nazwa
                </label>
            </div>
            <div className="form-check mb-3">
                <input className="form-check-input" type="radio" name="flexRadioCal" id="flexRadioDeCal2" defaultChecked={visibleName===2} />
                <label className="form-check-label" htmlFor="flexRadioDeCal2">
                skrócona nazwa
                </label>
            </div>
            <div className='mb-3'>
                <div className='general'>
                <label className='generalLabel' >Ustawienia kalendarza dziennego</label>
                    <span>Wykonywane wsługi</span>
                    <div className="form-check">
                        <div className='mb-1 '>
                            <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleAddRowToServicesTable}>Dodaj wiersz</button>
                        </div> 
                        <div className='servicesPanel'>   
                            <div className="input-group">
                                <input type="text" aria-label="First name" className="form-control" id='nameButton' defaultValue='nazwa przycisku' disabled/>
                                <input type="text" aria-label="Last name" className="form-control" id='timeService' defaultValue='czas trwania usługi' disabled/>
                                {/*<input type="text" aria-label="third name" className="form-control" />*/}
                            </div>
                            { loadObjectsButtonOfServices() }
                        </div> 
                    </div>
                </div>
            </div>

            <div className='mb-3'>
            <div className='general'>
            <label className='generalLabel' >Ustawienia kalendarza rocznego</label>
                <span>Wykonywane usługi</span>
                <div className="form-check">

                    <div className='servicesPanel'>   
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckVisibleDays" defaultChecked={ visibleDays }  />
                            <label className="form-check-label" forhtml="flexSwitchCheckVisibleDays">Widoczne tylko dni w danym miesiącu</label>
                        </div>

                        <span>Układ dni w kalendarzu</span>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioScheme" id="flexRadioScheme1" defaultChecked={schemeDaysCalendar===1} />
                            <label className="form-check-label" htmlFor="flexRadioScheme1" >
                            pionowy
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="radio" name="flexRadioScheme" id="flexRadioScheme2" defaultChecked={schemeDaysCalendar===2} />
                            <label className="form-check-label" htmlFor="flexRadioScheme2" >
                            poziomy
                            </label>
                        </div>
                 
                    </div> 
                </div>
                </div>
            </div>

        </div>  
        <div className='mb-3'>
            <div className='general'>
            <label className='generalLabel' >Informacje o Visit System Manager </label>
                <div>
                <span>Id urządzenia: {deviceId}</span>
                </div>
            </div>
        </div>      
            <div style={{float: 'right' , padding: '0px 30px'}}>
                <div >
                    <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleSaveSettings}>Zapisz</button>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default SettingsApp;