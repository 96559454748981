export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const SET_POINTS = 'SET_POINTS';
export const SET_BACKUP_LOCAL = 'SET_BACKUP_LOCAL';

export const loadSettings = ( settings ) => ({
    type: LOAD_SETTINGS ,
    payload: settings
  });

export const setPoints = ( points ) => ({
    type: SET_POINTS ,
    payload: points
  }); 

 export const setDeviceInfo = ( isBackupLocal , lastLocalBackup , deviceId ) => ({
    type: SET_BACKUP_LOCAL ,
    payload: {isBackupLocal , lastLocalBackup , deviceId}
 })