import { LOAD_SETTINGS , SET_POINTS , SET_BACKUP_LOCAL } from '../actions/settingsActions';

//const settState = {};
let _lastLocalBackup = 0;
export const settings = (state = null, action) => { // (1)
  
    switch (action.type) { // (2)
      case LOAD_SETTINGS: {
      //jeżeli nie występuje   
      let _p = -999;
      let _isBackupLocal = 0;
      let _deviceId = '';
      //let _lastLocalBackup = 0;

      if(state) {
        if(state.points) {
          _p = state.points>-1 ? state.points : -999;
        }
        if(state.isBackupLocal) {
          _isBackupLocal = state.isBackupLocal = 1 ? 1 : 0;
        }
        if(state.lastLocalBackup) {
          _lastLocalBackup = state.lastLocalBackup && state.lastLocalBackup;
        }
        if(state.deviceId) {
          _deviceId = state.deviceId && state.deviceId;
        }
      }  
      let _st = {
        active_notification:action.payload.active_notification,
        buttons_services:action.payload.buttons_services,
        company_logo:action.payload.company_logo,
        company_long_name:action.payload.company_long_name,
        company_short_name:action.payload.company_short_name,
        day_interval:action.payload.day_interval,
        sender_name:action.payload.sender_name,
        text_notification:action.payload.text_notification,
        time_zone:action.payload.time_zone,
        vertical_annual_calendar:action.payload.vertical_annual_calendar,
        visible_name_worker:action.payload.visible_name_worker,
        visible_not_current_days:action.payload.visible_not_current_days,
        points:_p,
        sms_api_sms_price:action.payload.sms_api_sms_price,
        sms_api_min_limit_alert:parseInt(action.payload.sms_api_min_limit_alert),
        isBackupLocal: _isBackupLocal ,
        lastLocalBackup: _lastLocalBackup ,
        deviceId: _deviceId
      }
       return _st; 
      } 
      case SET_POINTS: {
        let _isBackupLocal = 0;
        let _deviceId = '';
        //let _lastLocalBackup = 0;
        if(state) {
          if(state.isBackupLocal) {
            _isBackupLocal = state.isBackupLocal = 1 ? 1 : 0;
            _lastLocalBackup = state.lastLocalBackup && state.lastLocalBackup;
          }
        if(state.deviceId) {
            _deviceId = state.deviceId && state.deviceId;
          }  
        } 
        let _st = {
          active_notification:state.active_notification,
          buttons_services:state.buttons_services,
          company_logo:state.company_logo,
          company_long_name:state.company_long_name,
          company_short_name:state.company_short_name,
          day_interval:state.day_interval,
          sender_name:state.sender_name,
          text_notification:state.text_notification,
          time_zone:state.time_zone,
          vertical_annual_calendar:state.vertical_annual_calendar,
          visible_name_worker:state.visible_name_worker,
          visible_not_current_days:state.visible_not_current_days,
          points:parseFloat(action.payload),
          sms_api_sms_price:state.sms_api_sms_price,
          sms_api_min_limit_alert:state.sms_api_min_limit_alert,
          isBackupLocal: _isBackupLocal,
          lastLocalBackup: _lastLocalBackup , 
          deviceId: _deviceId
        }
         return _st; 
        }   
        
        case SET_BACKUP_LOCAL: {
          let _p = -999;
          let _deviceId = '';
          let _lastLocalBackup = 0;
          if(state) {
            if(state.points) {
              _p = state.points>-1 ? state.points : -999;
            }

          } 
          const isBL = parseInt(action.payload.isBackupLocal) ? 1 : 0;
          if(action.payload.lastLocalBackup) _lastLocalBackup = action.payload.lastLocalBackup;
          if(action.payload.deviceId) _deviceId = action.payload.deviceId;
          

          //
          let _st = {
            active_notification:state.active_notification,
            buttons_services:state.buttons_services,
            company_logo:state.company_logo,
            company_long_name:state.company_long_name,
            company_short_name:state.company_short_name,
            day_interval:state.day_interval,
            sender_name:state.sender_name,
            text_notification:state.text_notification,
            time_zone:state.time_zone,
            vertical_annual_calendar:state.vertical_annual_calendar,
            visible_name_worker:state.visible_name_worker,
            visible_not_current_days:state.visible_not_current_days,
            points:_p,
            sms_api_sms_price:state.sms_api_sms_price,
            sms_api_min_limit_alert:state.sms_api_min_limit_alert,
            isBackupLocal: isBL,
            lastLocalBackup: _lastLocalBackup , 
            deviceId: _deviceId
          }
           return _st; 
          }   

      default: {
        return state ;
        }
    }
  }